import React, { useEffect, useState } from "react";
import { Breadcrumb, Button, Spin, Collapse } from "antd";
import { Link } from "react-router-dom";
import dashboardService from "../services/o2w-dashboard-service";
import { NotificationManager } from "react-notifications";
import {
  TabAllDetailsData,
  TabPoDetailsData,
  TabMaterialDetailsData,
  TabInvoiceDetailsData,
  TabVehicleDetailsData,
  TabAssumptionDetailsData
} from "./TabDetailsCommanData";
import { DoubleLeftOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import moment from "moment";
import { checkTabTypeName } from "../helper/commonData";

const DetailsView = ({ type, id }) => {
  const TableData = useSelector((state) => state.TableListData.tableListData);
  const [tableDetails, setTableDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [tabTypeName, setTabTypeName] = useState("");
  const [activeCollapseKey, setActiveCollapseKey] = useState([]);

  useEffect(() => {
    if (id !== "0") {
      let finalType = checkTabTypeName(type);
      setTabTypeName(finalType);
      switch (type) {
        case "all":
          handleGetTabAllDetailsView();
          break;
        case "po":
          handleGetTabPODetailsView();
          break;
        case "material":
          handleGetTabMaterialDetailsView();
          break;
        case "invoice":
          handleGetTabInvoiceDetailsView();
          break;
        case "vehicle":
          handleGetTabVehicleDetailsView();
          break;
        case "assumption":
          handleGetTabAssumptionETADetailsView();
          break;

        default:
          handleGetTabAllDetailsView();
          break;
      }
    }
  }, [type]);
  /// ALL Tab View API
  const handleGetTabAllDetailsView = () => {
    setIsLoading(true);
    let inputData = {
      sap_Order_Number: id.toString()
    };
    dashboardService
      .getAllDetailsDashboardAPI(inputData)
      .then((response) => {
        if (response.data.statusCode === 200) {
          let finalData = response.data.responseData;
          if (finalData.length > 0) {
            let collapseKeys = finalData.map((_, index) =>
              (index + 1).toString()
            );
            setActiveCollapseKey(collapseKeys);
            setTableDetails(finalData);
          } else {
            setTableDetails([]);
            setActiveCollapseKey([]);
          }
          setIsLoading(false);
        } else {
          NotificationManager.error(response.data.message);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error, "Error");
      });
  };
  /// PO Tab View API
  const handleGetTabPODetailsView = () => {
    setIsLoading(true);
    let objData = TableData.find((x) => x.sap_Order_Number === id);
    let inputData = {
      sap_Order_Number: id.toString(),
      customer_PO_Number: objData.customer_PO_Number
    };
    dashboardService
      .getPoDetailsDashboardAPI(inputData)
      .then((response) => {
        if (response.data.statusCode === 200) {
          let finalData = response.data.responseData.poDetailsList;
          if (finalData.length > 0) {
            let collapseKeys = finalData.map((_, index) =>
              (index + 1).toString()
            );
            setActiveCollapseKey(collapseKeys);
            setTableDetails(finalData);
          } else {
            setTableDetails([]);
            setActiveCollapseKey([]);
          }
          setIsLoading(false);
        } else {
          NotificationManager.error(response.data.message);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error, "Error");
      });
  };
  /// Material Tab View API
  const handleGetTabMaterialDetailsView = () => {
    setIsLoading(true);
    let objData = TableData.find((x) => x.customer_PO_Number === id);
    let inputData = {
      material_id: objData.material_id,
      customer_PO_Number: id.toString(),
      customer_PO_Date: objData.customer_PO_Date
    };
    dashboardService
      .getMaterialDetailsDashboardAPI(inputData)
      .then((response) => {
        if (response.data.statusCode === 200) {
          let finalData = response.data.responseData.materialDetailsList;
          if (finalData.length > 0) {
            let collapseKeys = finalData.map((_, index) =>
              (index + 1).toString()
            );
            setActiveCollapseKey(collapseKeys);
            setTableDetails(finalData);
          } else {
            setTableDetails([]);
            setActiveCollapseKey([]);
          }
          setIsLoading(false);
        } else {
          NotificationManager.error(response.data.message);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error, "Error");
      });
  };
  /// Invoice Tab View API
  const handleGetTabInvoiceDetailsView = () => {
    setIsLoading(true);
    let objData = TableData.find((x) => x.invoice_Number === id);
    let inputData = {
      invoice_Number: id.toString(),
      material_Id: objData.material_Id,
      order_Schedule_Id: objData.order_Schedule_Id
    };
    dashboardService
      .getInvoiceDetailsDashboardAPI(inputData)
      .then((response) => {
        if (response.data.statusCode === 200) {
          let finalData = response.data.responseData.invoiceDetailsList;
          if (finalData.length > 0) {
            let collapseKeys = finalData.map((_, index) =>
              (index + 1).toString()
            );
            setActiveCollapseKey(collapseKeys);
            setTableDetails(finalData);
          } else {
            setTableDetails([]);
            setActiveCollapseKey([]);
          }
          setIsLoading(false);
        } else {
          NotificationManager.error(response.data.message);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error, "Error");
      });
  };
  //// Vehicle/Container Tab View API
  const handleGetTabVehicleDetailsView = () => {
    setIsLoading(true);
    let objData = TableData.find(
      (x) => x.container_Or_Vehicle_ID?.replace(/ /g, "") === id
    );
    let inputData = {
      vehicle_Number: objData.vehicle_Number,
      sap_Order_Number: objData.sap_Order_Number,
      invoice_Number: objData.invoice_Number,
      container_Number: objData.container_Number
    };
    dashboardService
      .getVehicleContainerDashboardAPI(inputData)
      .then((response) => {
        if (response.data.statusCode === 200) {
          let finalData =
            response.data.responseData.vehicleContainerDetailsList;
          if (finalData.length > 0) {
            let collapseKeys = finalData.map((_, index) =>
              (index + 1).toString()
            );
            setActiveCollapseKey(collapseKeys);
            setTableDetails(finalData);
          } else {
            setTableDetails([]);
            setActiveCollapseKey([]);
          }
          setIsLoading(false);
        } else {
          NotificationManager.error(response.data.message);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error, "Error");
      });
  };
  //// Assumption ETA Tab View API
  const handleGetTabAssumptionETADetailsView = () => {
    setIsLoading(true);
    let objData = TableData.find((x) => x.sap_Order_Number === id);
    let inputData = {
      sap_Order_Number: objData.sap_Order_Number,
      invoice_Number: objData.invoice_Number,
      estimated_Plan_Date: objData.estimated_Plan_Date
    };
    dashboardService
      .getAssumptionETADashboardAPI(inputData)
      .then((response) => {
        if (response.data.statusCode === 200) {
          let finalData = response.data.responseData.assumptionDetailsList;
          if (finalData.length > 0) {
            let collapseKeys = finalData.map((_, index) =>
              (index + 1).toString()
            );
            setActiveCollapseKey(collapseKeys);
            setTableDetails(finalData);
          } else {
            setTableDetails([]);
            setActiveCollapseKey([]);
          }
          setIsLoading(false);
        } else {
          NotificationManager.error(response.data.message);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error, "Error");
      });
  };

  return (
    <div className="content">
      <div className="content-wrapper">
        <div className="content-body">
          <Breadcrumb className="details_breadcrum" separator=">">
            <Breadcrumb.Item>
              <Link to={`/dashboard/${type}`}>Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <label>{type === "po" ? "PO" : type}</label>
            </Breadcrumb.Item>
          </Breadcrumb>
          <div className="details_view_title">
            <h5 class="content-header-title d-inline-block tab_cap">
              Order to Warehouse - {tabTypeName}
            </h5>
            <Link to={`/dashboard/${type}`}>
              <Button type="primary back_btn" icon={<DoubleLeftOutlined />}>
                Back
              </Button>
            </Link>
          </div>
          
          <div className="card">
            <Spin size="large" spinning={isLoading}>
              <div className="card-body d-flex">
                {tableDetails.length > 0 && (
                  <Collapse
                    defaultActiveKey={activeCollapseKey}
                    expandIconPosition="end"
                    className="details_collapse"
                  >
                    {tableDetails.map((item, i) => {
                      return (
                        <Collapse.Panel key={i + 1} header={`Customer PO :-  ${item.customer_PO_Number}`}>
                          <div className="details_collapse_body">
                            <div className="row">
                              {Object.keys(item).map((objKey, j) => {
                                let objData = {};
                                switch (type) {
                                  case "all":
                                    objData = TabAllDetailsData(objKey) || {};
                                    break;
                                  case "po":
                                    objData = TabPoDetailsData(objKey) || {};
                                    break;
                                  case "material":
                                    objData =
                                      TabMaterialDetailsData(objKey) || {};
                                    break;
                                  case "invoice":
                                    objData =
                                      TabInvoiceDetailsData(objKey) || {};
                                    break;
                                  case "vehicle":
                                    objData =
                                      TabVehicleDetailsData(objKey) || {};
                                    break;
                                  case "assumption":
                                    objData =
                                      TabAssumptionDetailsData(objKey) || {};
                                    break;
                                  default:
                                    break;
                                }
                                return (
                                  <div
                                    className="col-lg-3 col-md-4 col-sm-6"
                                    key={j}
                                  >
                                    {Object.keys(objData).length > 0 && (
                                      <div className="d-grid py-3 px-4">
                                        <span className="label fw-bold">
                                          {objData.label}:
                                        </span>
                                        {objData.isDate ? (
                                          <span className="value">
                                            {item[objKey] !== null &&
                                            item[objKey] !== ""
                                              ? moment(item[objKey]).format(
                                                  "DD-MM-YYYY"
                                                )
                                              : "N/A"}
                                          </span>
                                        ) : (
                                          <span className="value">
                                            {item[objKey] !== null &&
                                            item[objKey] !== ""
                                              ? item[objKey]
                                              : "N/A"}
                                          </span>
                                        )}
                                      </div>
                                    )}
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </Collapse.Panel>
                      );
                    })}
                  </Collapse>
                )}
              </div>
            </Spin>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailsView;
