import React, { Fragment, useEffect, useState } from "react";
// import { InfoCircleTwoTone } from "@ant-design/icons";
import { Link, 
  // useNavigate 
} from "react-router-dom";
import { Table } from "antd";
import formatNumber from "../../../../utils/formatNumber";

const CustomerAgingTbl = ({
  customerAgingData,
  customerAgingLoading,
  OnhandleTableSortColumn,
}) => {
  // const navigate = useNavigate();

  const [totalPendingLCY, setTotalPendingLCY] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [pageNo, setPageNo] = useState(1);

  useEffect(() => {
    if (customerAgingData.customerAgeingList?.length > 0) {
      setTotalPendingLCY(customerAgingData.total);
      setTotalRecords(customerAgingData.totalRecords);
      setPageNo(Number(customerAgingData.currentPage));
    } else {
      setTotalPendingLCY(0);
      setTotalRecords(0);
      setPageNo(1);
    }
  }, [customerAgingData.customerAgeingList]);

  const handleTableAscDes = (pagination, filters, sorter) => {
    OnhandleTableSortColumn({
      currentPage: pagination.current,
      table: "Customer Aging",
    });
  };

  // const handleOverviewDetailsPage = (rowData) => {
  //   navigate(`/overviewDetails/all/ZPF4419`);
  // };

  const tableColumns = [
    {
      title: "Customer Name",
      dataIndex: "customerOrganization",
      sorter: (a, b) => a.customerOrganization - b.customerOrganization,
      showSorterTooltip: false,
      width: 250,
      render: (_i, row) => {
        return (
          <div>
            <Link to={`/detailsView/all/2000243`}>
              <label className="cust_po_no_lbl">
                {row.customerOrganization}
              </label>
            </Link>
          </div>
        );
      },
    },
    {
      title: "Pending Amount LCY",
      dataIndex: "totalPendingAmount_LCY",
      sorter: (a, b) =>
        parseFloat(a.totalPendingAmount_LCY.replace(/,/g, "")) -
        parseFloat(b.totalPendingAmount_LCY.replace(/,/g, "")),
      showSorterTooltip: false,
      render: (text, record) =>
        `${record.currency} ${formatNumber(
          parseFloat(record.totalPendingAmount_LCY.replace(/,/g, ""))
        )}`,
      width: 160,
    },
    {
      title: "0-10 Days",
      dataIndex: "first",
      sorter: (a, b) =>
        parseFloat(a["first"].replace(/,/g, "")) -
        parseFloat(b["first"].replace(/,/g, "")),
      showSorterTooltip: false,
      width: 120,
      render: (_i, row) => {
        return (
          <div>
            {row.first !== ""
              ? formatNumber(parseFloat(row.first.replace(/,/g, "")))
              : "-"}
          </div>
        );
      },
    },
    {
      title: "11-15 Days",
      dataIndex: "second",
      sorter: (a, b) =>
        parseFloat(a["second"].replace(/,/g, "")) -
        parseFloat(b["second"].replace(/,/g, "")),
      showSorterTooltip: false,
      width: 120,
      render: (_i, row) => {
        return (
          <div>
            {row.second !== ""
              ? formatNumber(parseFloat(row.second.replace(/,/g, "")))
              : "-"}
          </div>
        );
      },
    },
    {
      title: "16-30 Days",
      dataIndex: "third",
      sorter: (a, b) =>
        parseFloat(a["third"].replace(/,/g, "")) -
        parseFloat(b["third"].replace(/,/g, "")),
      showSorterTooltip: false,
      width: 120,
      render: (_i, row) => {
        return (
          <div>
            {row.third !== ""
              ? formatNumber(parseFloat(row.third.replace(/,/g, "")))
              : "-"}
          </div>
        );
      },
    },
    {
      title: "31-45 Days",
      dataIndex: "fourth",
      sorter: (a, b) =>
        parseFloat(a["fourth"].replace(/,/g, "")) -
        parseFloat(b["fourth"].replace(/,/g, "")),
      showSorterTooltip: false,
      width: 120,
      render: (_i, row) => {
        return (
          <div>
            {row.fourth !== ""
              ? formatNumber(parseFloat(row.fourth.replace(/,/g, "")))
              : "-"}
          </div>
        );
      },
    },
    {
      title: "46-60 Days",
      dataIndex: "fifth",
      sorter: (a, b) =>
        parseFloat(a["fifth"].replace(/,/g, "")) -
        parseFloat(b["fifth"].replace(/,/g, "")),
      showSorterTooltip: false,
      width: 120,
      render: (_i, row) => {
        return (
          <div>
            {row.fifth !== ""
              ? formatNumber(parseFloat(row.fifth.replace(/,/g, "")))
              : "-"}
          </div>
        );
      },
    },
    {
      title: ">60 Days",
      dataIndex: "sixth",
      sorter: (a, b) =>
        parseFloat(a["sixth"].replace(/,/g, "")) -
        parseFloat(b["sixth"].replace(/,/g, "")),
      showSorterTooltip: false,
      width: 120,
      render: (_i, row) => {
        return (
          <div>
            {row.sixth !== ""
              ? formatNumber(parseFloat(row.sixth.replace(/,/g, "")))
              : "-"}
          </div>
        );
      },
    },
    // {
    //   title: "Action",
    //   dataIndex: "action",
    //   width: 80,
    //   fixed: "right",
    //   render: (_i, row) => (
    //     <div
    //       className="tbl_info_details"
    //       onClick={() => handleOverviewDetailsPage(row)}
    //     >
    //       <InfoCircleTwoTone />
    //     </div>
    //   ),
    // },
  ];

  return (
    <Fragment>
      <Table
        bordered
        scroll={{ x: 900 }}
        className="dashboard_tables payment_table"
        dataSource={customerAgingData.customerAgeingList}
        loading={customerAgingLoading}
        onChange={handleTableAscDes}
        pagination={{
          total: totalRecords,
          pageNo: pageNo,
          pageSize: 10,
          responsive: true,
          showSizeChanger: false,
          current: pageNo,
          onChange: (pageNum) => setPageNo(pageNum),
        }}
        columns={tableColumns}
        summary={() => {
          return (
            <Table.Summary.Row className="tbl_summary">
              <Table.Summary.Cell colSpan={1} style={{ fontWeight: "bold" }}>
                Total
              </Table.Summary.Cell>
              <Table.Summary.Cell>
                {formatNumber(totalPendingLCY)}
              </Table.Summary.Cell>
              <Table.Summary.Cell />
              <Table.Summary.Cell />
              <Table.Summary.Cell />
              <Table.Summary.Cell />
              <Table.Summary.Cell />
              <Table.Summary.Cell />
            </Table.Summary.Row>
          );
        }}
      />
    </Fragment>
  );
};

export default CustomerAgingTbl;
