import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Table } from "antd";
import moment from "moment";
import formatNumber from "../../../../utils/formatNumber";

const InvoiceDetailsTbl = ({
  invoiceDetailsData,
  isLoading,
  OnhandleTableSortColumn,
}) => {
  const [totalRecords, setTotalRecords] = useState(0);
  const [pageNo, setPageNo] = useState(1);

  useEffect(() => {
    if (invoiceDetailsData.invoiceWisePaymentList?.length > 0) {
      setTotalRecords(invoiceDetailsData.totalRecords);
      setPageNo(Number(invoiceDetailsData.currentPage));
    } else {
      setTotalRecords(0);
      setPageNo(1);
    }
  }, [invoiceDetailsData.invoiceWisePaymentList]);

  const handleTableAscDes = (pagination, filters, sorter) => {
    OnhandleTableSortColumn({
      currentPage: pagination.current,
      table: "Invoice Details",
    });
  };

  const tableColumns = [
    {
      title: "Customer",
      dataIndex: "customerOrganization",
      sorter: (a, b) => a.customerOrganization - b.customerOrganization,
      showSorterTooltip: false,
      width: 230,
      fixed: "left",
      render: (_i, row) => {
        return (
          <div>
            <Link to={`/detailsView/all/2000243`}>
              <label className="cust_po_no_lbl">
                {row.customerOrganization}
              </label>
            </Link>
          </div>
        );
      },
    },
    {
      title: "Invoice No",
      dataIndex: "invoiceNumber",
      sorter: (a, b) =>
        parseFloat(a.invoiceNumber.replace(/,/g, "")) -
        parseFloat(b.invoiceNumber.replace(/,/g, "")),
      showSorterTooltip: false,
      width: 120,
      fixed: "left",
    },
    {
      title: "Invoice Date",
      dataIndex: "invoiceDate",
      sorter: (a, b) => new Date(a.invoiceDate) - new Date(b.invoiceDate),
      showSorterTooltip: false,
      width: 120,
      fixed: "left",
      render: (_i, row) => {
        return (
          <div>
            {row.invoiceDate
              ? moment(row.invoiceDate).format("DD-MM-YYYY")
              : "N/A"}
          </div>
        );
      },
    },
    {
      title: "Due Date",
      dataIndex: "dueDate",
      sorter: (a, b) => new Date(a.dueDate) - new Date(b.dueDate),
      showSorterTooltip: false,
      width: 120,
      fixed: "left",
      render: (_i, row) => {
        return (
          <div>
            {row.dueDate ? moment(row.dueDate).format("DD-MM-YYYY") : "N/A"}
          </div>
        );
      },
    },
    {
      title: "Pending Amount LCY",
      dataIndex: "pendingAmtLcy",
      sorter: (a, b) =>
        parseFloat(a.pendingAmtLcy.replace(/,/g, "")) -
        parseFloat(b.pendingAmtLcy.replace(/,/g, "")),
      showSorterTooltip: false,
      width: 160,
      fixed: "left",
      render: (text, record) =>
        `${record.currency} ${formatNumber(
          parseFloat(record.totalPendingAmount_LCY.replace(/,/g, ""))
        )}`,
    },
    {
      title: "Invoice Amount",
      dataIndex: "pendingAmtLcy",
      sorter: (a, b) =>
        parseFloat(a.pendingAmtLcy.replace(/,/g, "")) -
        parseFloat(b.pendingAmtLcy.replace(/,/g, "")),
      showSorterTooltip: false,
      width: 160,
      render: (text, record) =>
        `${record.currency} ${formatNumber(
          parseFloat(record.totalPendingAmount_LCY.replace(/,/g, ""))
        )}`,
    },
    {
      title: "Undue Amount",
      dataIndex: "pendingAmtLcy",
      sorter: (a, b) =>
        parseFloat(a.pendingAmtLcy.replace(/,/g, "")) -
        parseFloat(b.pendingAmtLcy.replace(/,/g, "")),
      showSorterTooltip: false,
      width: 160,
      render: (text, record) =>
        `${record.currency} ${formatNumber(
          parseFloat(record.totalPendingAmount_LCY.replace(/,/g, ""))
        )}`,
    },
    {
      title: "Unadjusted Amount",
      dataIndex: "pendingAmtLcy",
      sorter: (a, b) =>
        parseFloat(a.pendingAmtLcy.replace(/,/g, "")) -
        parseFloat(b.pendingAmtLcy.replace(/,/g, "")),
      showSorterTooltip: false,
      width: 160,
      render: (text, record) =>
        `${record.currency} ${formatNumber(
          parseFloat(record.totalPendingAmount_LCY.replace(/,/g, ""))
        )}`,
    },
    {
      title: "Pending Amount",
      dataIndex: "pendingAmtLcy",
      sorter: (a, b) =>
        parseFloat(a.pendingAmtLcy.replace(/,/g, "")) -
        parseFloat(b.pendingAmtLcy.replace(/,/g, "")),
      showSorterTooltip: false,
      width: 160,
      render: (text, record) =>
        `${record.currency} ${formatNumber(
          parseFloat(record.totalPendingAmount_LCY.replace(/,/g, ""))
        )}`,
    },
    {
      title: "Total Credit Limit",
      dataIndex: "pendingAmtLcy",
      sorter: (a, b) =>
        parseFloat(a.pendingAmtLcy.replace(/,/g, "")) -
        parseFloat(b.pendingAmtLcy.replace(/,/g, "")),
      showSorterTooltip: false,
      width: 160,
      render: (text, record) =>
        `${record.currency} ${formatNumber(
          parseFloat(record.totalPendingAmount_LCY.replace(/,/g, ""))
        )}`,
    },
    {
      title: "Credit Days",
      dataIndex: "pendingAmtLcy",
      sorter: (a, b) =>
        parseFloat(a.pendingAmtLcy.replace(/,/g, "")) -
        parseFloat(b.pendingAmtLcy.replace(/,/g, "")),
      showSorterTooltip: false,
      width: 160,
      render: (text, record) =>
        `${record.currency} ${formatNumber(
          parseFloat(record.totalPendingAmount_LCY.replace(/,/g, ""))
        )}`,
    },
    {
      title: "Overdue Days",
      dataIndex: "pendingAmtLcy",
      sorter: (a, b) =>
        parseFloat(a.pendingAmtLcy.replace(/,/g, "")) -
        parseFloat(b.pendingAmtLcy.replace(/,/g, "")),
      showSorterTooltip: false,
      width: 160,
      render: (text, record) =>
        `${record.currency} ${formatNumber(
          parseFloat(record.totalPendingAmount_LCY.replace(/,/g, ""))
        )}`,
    },
    {
      title: "Today Total Days",
      dataIndex: "pendingAmtLcy",
      sorter: (a, b) =>
        parseFloat(a.pendingAmtLcy.replace(/,/g, "")) -
        parseFloat(b.pendingAmtLcy.replace(/,/g, "")),
      showSorterTooltip: false,
      width: 160,
      render: (text, record) =>
        `${record.currency} ${formatNumber(
          parseFloat(record.totalPendingAmount_LCY.replace(/,/g, ""))
        )}`,
    },
    {
      title: "0-10 Days",
      dataIndex: "first",
      showSorterTooltip: false,
      width: 120,
    },
    {
      title: "11-15 Days",
      dataIndex: "second",
      showSorterTooltip: false,
      width: 120,
    },
    {
      title: "16-30 Days",
      dataIndex: "third",
      showSorterTooltip: false,
      width: 120,
    },
    {
      title: "31-45 Days",
      dataIndex: "third",
      showSorterTooltip: false,
      width: 120,
    },
    {
      title: "46-60 Days",
      dataIndex: "third",
      showSorterTooltip: false,
      width: 120,
    },
    {
      title: ">60 Days",
      dataIndex: "third",
      showSorterTooltip: false,
      width: 120,
    },
  ];

  return (
    <Fragment>
      <Table
        bordered
        scroll={{ x: 900 }}
        className="dashboard_tables payment_table"
        dataSource={invoiceDetailsData.invoiceWisePaymentList}
        loading={isLoading}
        columns={tableColumns}
        onChange={handleTableAscDes}
        pagination={{
          total: totalRecords,
          pageNo: pageNo,
          pageSize: 10,
          responsive: true,
          showSizeChanger: false,
          current: pageNo,
          onChange: (pageNum) => setPageNo(pageNum),
        }}
      />
    </Fragment>
  );
};

export default InvoiceDetailsTbl;
