import React, { useState, useEffect, useRef } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import formatNumber from "../../../../utils/formatNumber";

const PAGE_SIZE = 100;

const CustomerBarChart = ({ BarChartData, handleBarClick }) => {
  const [visibleData, setVisibleData] = useState([]);
  const [startIndex, setStartIndex] = useState(0);
  const scrollRef = useRef(null);

  useEffect(() => {
    if (BarChartData && BarChartData.length > 0) {
      setVisibleData(BarChartData.slice(0, PAGE_SIZE));
    }
  }, [BarChartData]);

  useEffect(() => {
    scrollRef.current.scrollTop = 50;
  }, [visibleData]);

  const handleScroll = () => {
    if (scrollRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;

      if (scrollTop === 0) {
        if (startIndex > 0) {
          loadPreviousData();
          scrollRef.current.scrollTop = 50;
        }
      } else if (scrollTop + clientHeight >= scrollHeight) {
        if (startIndex + PAGE_SIZE < BarChartData.length) {
          loadNextData();
        }
      }
    }
  };

  const loadNextData = () => {
    const newStartIndex = startIndex + PAGE_SIZE;
    const newVisibleData = BarChartData.slice(
      newStartIndex,
      newStartIndex + PAGE_SIZE
    );
    // setVisibleData((prevData) => [...prevData, ...newVisibleData]);
    setVisibleData(newVisibleData);
    setStartIndex(newStartIndex);
  };

  const loadPreviousData = () => {
    const newStartIndex = Math.max(0, startIndex - PAGE_SIZE);
    const newVisibleData = BarChartData.slice(newStartIndex, startIndex);
    setVisibleData(newVisibleData);
    setStartIndex(newStartIndex);
  };

  const truncateCustomerName = (customerOrganization) => {
    return customerOrganization?.length > 16
      ? customerOrganization.substring(0, 16) + "..."
      : customerOrganization;
  };

  const chartData = visibleData.map((data) => ({
    name: truncateCustomerName(data.customerOrganization),
    totalPendingAmount_LCY: parseFloat(data.totalPendingAmount_LCY),
    currency: data.currency,
    fullName: data.customerOrganization,
    customerId: data.customerId,
  }));

  const chartHeight = Math.max(200, BarChartData?.length * 5);

  return (
    <div className="app">
      <div
        className="pay_cust_chart"
        ref={scrollRef}
        style={{
          height: "295px",
          overflowY: BarChartData?.length > PAGE_SIZE ? "auto" : "hidden",
          overflowX: "hidden",
          marginTop: "5px",
        }}
        onScroll={handleScroll}
      >
        <ResponsiveContainer width="100%" height={chartHeight}>
          <BarChart
            data={chartData}
            margin={{ top: 5, right: 10, left: -160, bottom: 5 }}
            layout="vertical"
          >
            <XAxis type="number" hide={true} />
            <YAxis
              dataKey="name"
              tick={{ fontSize: 12 }}
              type="category"
              width={300}
              style={{
                overflow: "visible",
                textOverflow: "inherit",
              }}
              interval={0}
            />

            <Tooltip
              formatter={(value, name, props) => [
                formatNumber(value) + " " + props.payload.currency,
                "Pending Amount",
              ]}
              labelFormatter={(label, payload) => {
                if (payload && payload.length > 0) {
                  const dataIndex = payload[0]?.payload;
                  return dataIndex?.fullName || label;
                }
                return label;
              }}
            />

            <Bar
              dataKey="totalPendingAmount_LCY"
              fill="#da4453"
              label={({ x, y, width, height, value }) => {
                const formattedValue = formatNumber(value);
                const textWidth = formattedValue.length * 6;
                let displayText = "";

                if (textWidth <= width) {
                  displayText = formattedValue;
                } else if (width > 0) {
                  if (width < 15) {
                    displayText = "";
                  } else if (width < 18) {
                    displayText = `${formattedValue.slice(0, 1)}`;
                  } else if (width < 20) {
                    displayText = `${formattedValue.slice(0, 1)}.`;
                  } else if (width < 40) {
                    displayText = `${formattedValue.slice(0, 2)}..`;
                  } else {
                    displayText = `${formattedValue.slice(0, 3)}...`;
                  }
                }

                return (
                  <text
                    x={x + 5}
                    y={y + height / 2 + 5}
                    fill="#FFF"
                    fontSize={12}
                    textAnchor="start"
                    dominantBaseline="middle"
                  >
                    {displayText}
                  </text>
                );
              }}
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default CustomerBarChart;
