import React from "react";
import Chart from "react-apexcharts";

const InvoiceOutstandingBarChart = () => {
  const options = {
    chart: {
      type: "bar",
      toolbar: { show: false },
    },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "70%",
      },
    },
    annotations: {
      yaxis: [
        {
          y: 30,
          borderColor: "red",
          label: {
            borderColor: "red",
            style: {
              color: "#fff",
              background: "red",
            },
          },
        },
        {
          y: 50,
          borderColor: "blue",
          label: {
            borderColor: "blue",
            style: {
              color: "#fff",
              background: "blue",
            },
          },
        },
      ],
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      position: "top",
      horizontalAlign: "right",
      fontSize: "12px",
      itemMargin: {
        horizontal: 10,
        vertical: 5,
      },
      markers: {
        width: 50,
        height: 8,
      },
    },
    colors: ["#87C5FC", "#1FE397", "#ACACAC"],
  };

  const series = [
    {
      name: "Invoice",
      data: [40, 60, 80, 100, 90, 70, 60, 50, 40, 30, 20, 10],
    },
    {
      name: "Collection",
      data: [30, 50, 70, 90, 80, 60, 50, 40, 30, 20, 10, 5],
    },
    {
      name: "O/S",
      data: [20, 40, 60, 80, 70, 50, 40, 30, 20, 10, 5, 0],
    },
  ];

  return (
    <div>
      <Chart options={options} series={series} type="bar" height={350} />
    </div>
  );
};

export default InvoiceOutstandingBarChart;
