import { httpTokenCommon } from "../../helper/http-common";

class PaymentService {
  getPaymentBarChartDataAPI(inputData) {
    return httpTokenCommon.post("Payment/GetPaymentDashboardData", inputData);
  }
  getPaymentCurrencyWiseDataAPI(inputData) {
    return httpTokenCommon.post("Payment/GetTotalPendingAmount", inputData);
  }
  getPaymentCustomerWisePendingDataAPI(inputData) {
    return httpTokenCommon.post(
      "Payment/GetCustomerWisePendingData",
      inputData
    );
  }
  getPaymentCustomerAgeingDataAPI(inputData) {
    return httpTokenCommon.post(
      "Payment/GetCustomerAgeingPaymentData",
      inputData
    );
  }
  getPaymentInvoiceDataAPI(inputData) {
    return httpTokenCommon.post("Payment/GetInvoiceWisePaymentData", inputData);
  }
  getDistributionchannelDataAPI() {
    return httpTokenCommon.post("Payment/GetDistributionchannel");
  }
  getPrinceGroupDataAPI() {
    return httpTokenCommon.post("Payment/GetPriceGroup");
  }
  getSalesOfficeDataAPI() {
    return httpTokenCommon.post("Payment/GetSalesOffice");
  }
}

const paymentService = new PaymentService();
export default paymentService;
