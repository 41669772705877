import React, { Fragment, useEffect, useState } from "react";
import { InfoCircleTwoTone } from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Table, Tooltip } from "antd";
import {
  setTableListData,
  setTableActionDetails,
} from "../../actions/tableListing/tableListDataAction";

const ContainerTab = ({ tabTableData, isLoading, OnhandleTableSortColumn }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isO2WPage =
    window.location.pathname.split("/")[1] === "dashboard" ? true : false;

  const tableSorting = useSelector(
    (state) => state.TableSortingData.tableListData
  );

  const [totalRecords, setTotalRecords] = useState(0);
  const [pageNo, setPageNo] = useState(1);

  useEffect(() => {
    if (tabTableData.vehicleContainerTabsList?.length > 0) {
      setTotalRecords(tabTableData.totalRecords);
      setPageNo(Number(tabTableData.currentPage));
      dispatch(setTableListData(tabTableData.vehicleContainerTabsList));
    } else {
      setTotalRecords(0);
      setPageNo(1);
    }
  }, [tabTableData.vehicleContainerTabsList]);

  const handleTableAscDes = (pagination, filters, sorter) => {
    const sortOrder = sorter.order;
    const sortField = sorter.field;
    OnhandleTableSortColumn({
      sortColumn: sortField,
      orderBy:
        sortOrder === "ascend"
          ? "asc"
          : sortOrder === "descend"
          ? "desc"
          : "asc",
      currentPage: pagination.current,
    });
  };

  const handleOverviewDetailsPage = (rowData) => {
    dispatch(
      setTableActionDetails({
        labelId: rowData.customer_PO_Number,
        valueName: rowData.customer_PO_Number,
        SapOrderNumber: rowData.sap_Order_Number,
      })
    );
    navigate(
      `/${
        isO2WPage ? "o2w" : "logistic"
      }/overviewDetails/vehicle/${rowData.customer_PO_Number.replace(
        /[ \/]/g,
        ""
      )}`
    );
  };

  const tableColumns = [
    {
      title: "Vehicle ID",
      dataIndex: "container_Or_Vehicle_ID",
      sorter: true,
      showSorterTooltip: false,
      sortOrder:
        tableSorting.sortColumn === "container_Or_Vehicle_ID"
          ? tableSorting.orderBy === "asc"
            ? "ascend"
            : "descend"
          : null,
      width: 190,
      render: (_i, row) => {
        return (
          <div>
            <Link
              to={`/detailsView/vehicle/${row.container_Or_Vehicle_ID?.replace(
                / /g,
                ""
              )}`}
            >
              <label className="cust_po_no_lbl">
                {row.container_Or_Vehicle_ID}
              </label>
            </Link>
          </div>
        );
      },
    },
    {
      title: "Vehicle Number",
      dataIndex: "vehicle_Number",
      sorter: true,
      showSorterTooltip: false,
      sortOrder:
        tableSorting.sortColumn === "vehicle_Number"
          ? tableSorting.orderBy === "asc"
            ? "ascend"
            : "descend"
          : null,
      width: 190,
    },
    {
      title: "Container Number",
      dataIndex: "container_Number",
      sorter: true,
      showSorterTooltip: false,
      sortOrder:
        tableSorting.sortColumn === "container_Number"
          ? tableSorting.orderBy === "asc"
            ? "ascend"
            : "descend"
          : null,
      width: 200,
      render: (_i, row) => {
        return <div>{row.container_Number ? row.container_Number : "N/A"}</div>;
      },
    },
    {
      title: "Invoice Number",
      dataIndex: "invoice_Number",
      sorter: true,
      showSorterTooltip: false,
      sortOrder:
        tableSorting.sortColumn === "invoice_Number"
          ? tableSorting.orderBy === "asc"
            ? "ascend"
            : "descend"
          : null,
      width: 200,
    },
    {
      title: "Customer Organization Name",
      dataIndex: "customer_Organization_Name",
      sorter: true,
      showSorterTooltip: false,
      width: 280,
      sortOrder:
        tableSorting.sortColumn === "customer_Organization_Name"
          ? tableSorting.orderBy === "asc"
            ? "ascend"
            : "descend"
          : null,
      render: (_i, row) => {
        return (
          <div>
            <label>{row.customer_Organization_Name}</label>
          </div>
        );
      },
    },
    {
      title: "Customer PO Number",
      dataIndex: "customer_PO_Number",
      sorter: true,
      showSorterTooltip: false,
      sortOrder:
        tableSorting.sortColumn === "customer_PO_Number"
          ? tableSorting.orderBy === "asc"
            ? "ascend"
            : "descend"
          : null,
      width: 200,
      render: (_i, row) => {
        return (
          <div>
            <Tooltip placement="bottom" title={row.customer_PO_Number}>
              <p style={{ textDecoration: "none" }}>
                {row.customer_PO_Number?.length > 20
                  ? row.customer_PO_Number.substr(0, 20).concat("..")
                  : row.customer_PO_Number}
              </p>
            </Tooltip>
          </div>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      width: 80,
      fixed: "right",
      render: (_i, row) => (
        <div
          className="tbl_info_details"
          onClick={() => handleOverviewDetailsPage(row)}
        >
          <InfoCircleTwoTone />
        </div>
      ),
    },
  ];
  return (
    <Fragment>
      <Table
        bordered
        scroll={{ x: 900 }}
        className="dashboard_tables"
        dataSource={tabTableData.vehicleContainerTabsList}
        loading={isLoading}
        onChange={handleTableAscDes}
        pagination={{
          total: totalRecords,
          pageNo: pageNo,
          pageSize: 10,
          responsive: true,
          showSizeChanger: false,
          current: pageNo,
          onChange: (pageNum) => setPageNo(pageNum),
        }}
        columns={tableColumns}
      />
    </Fragment>
  );
};

export default ContainerTab;
