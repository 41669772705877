import React, { Fragment, useState, useEffect } from "react";
import { Card, Tabs, Button, Select, Spin } from "antd";
import { SettingOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { NotificationManager } from "react-notifications";
import GlobalFilterDrawer from "../../../components/GlobalFilterDrawer";
import CustomerTab from "./PaymentTabs/CustomerTab";
import CustomerAgingTbl from "./PaymentTabs/CustomerAgingTbl";
import InvoiceDetailsTbl from "./PaymentTabs/InvoiceDetailsTbl";
import {
  imgBank,
  imgFilter,
  SmallMTQ,
  SmallOrder,
  SmallMaterial,
  SmallQuantity,
} from "../imageAssets";
import paymentService from "../../../services/Payment/payment-service";
import eliteCardService from "../../../services/elite-cards-service";
import InvoiceOutstandingBarChart from "./PaymentTabs/InvoiceOutstandingBarChart";

const { TabPane } = Tabs;
const { Option } = Select;

const PaymentDashboard = ({ type }) => {
  const companyDetails = useSelector(
    (state) => state.CompanyDetails.companyDetials
  );

  const userDetails = useSelector(
    (state) => state.loginReducer.loginDetials.userDetails
  );

  const [isLoading, setIsLoading] = useState(false);
  const [customerAgingLoading, setCustomerAgingLoading] = useState(false);
  const [isGlobalFilter, setIsGlobalFilter] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isEmployee, setIsEmployee] = useState(false);
  const [applyFilterObj, setApplyFilterObj] = useState({});
  const [selectAnalysisType, setSelectAnalysisType] = useState("C");
  // const [tabWiseTableData, setTabWiseTableData] = useState([]);
  const [customerAgingData, setCustomerAgingData] = useState([]);
  const [invoiceDetailsData, setInvoiceDetailsData] = useState([]);
  const [totalAmountLCYCount, setTotalAmountLCYCount] = useState(0);
  const [tableObjColumn, setTableObjColumn] = useState({
    currentPage: "",
    table: "",
  });
  const [distributionChannelData, setDistributionChannelData] = useState([]);
  const [priceGroupData, setPriceGroupData] = useState([]);
  const [salesOfficeData, setSalesOfficeData] = useState([]);
  const [selectedDistributionIds, setSelectedDistributionIds] = useState([]);
  const [selectedPriceGroupIds, setSelectedPriceGroupIds] = useState([]);
  const [selectedSalesOfficeIds, setSelectedSalesOfficeIds] = useState([]);
  const [isSelectDistributionAll, setIsSelectDistributionAll] = useState(false);
  const [isSelectPriceGroupAll, setIsSelectPriceGroupAll] = useState(false);
  const [isSelectSalesOfficeAll, setIsSelectSalesOfficeAll] = useState(false);
  const [selectedBarFilter, setSelectedBarFilter] = useState([]);
  const [cardClasses, setCardClasses] = useState("");

  useEffect(() => {
    setIsEmployee(userDetails.isEmployee);
    handleGetPaymentDashboardCount();
    handleGetDistributionchannelData();
    handleGetPriceGroupData();
    handleGetSalesOfficeData();
  }, []);

  useEffect(() => {
    // handleGetCustomerAndCurrencyWiseOutstandingData();
    handleGetCustomerAgingDetailsAPI();
    handleGetInvoiceDetailsAPI();
  }, [
    selectAnalysisType,
    selectedDistributionIds,
    selectedPriceGroupIds,
    selectedSalesOfficeIds,
    selectedBarFilter,
  ]);

  useEffect(() => {
    if (tableObjColumn.table === "Customer Aging") {
      handleGetCustomerAgingDetailsAPI();
    }
    if (tableObjColumn.table === "Invoice Details") {
      handleGetInvoiceDetailsAPI();
    }
  }, [tableObjColumn]);

  useEffect(() => {
    if (userDetails.isEmployee) {
      setCardClasses("col-xl-3 col-lg-6 col-12");
    } else {
      setCardClasses("col-xl-4 col-lg-6 col-12");
    }
  }, [userDetails.isEmployee]);

  const handleApplyFilter = (objData) => {
    setApplyFilterObj(objData);
  };
  const showFilterDrawer = () => {
    setDrawerOpen((prevDrawerOpen) => !prevDrawerOpen);
    setIsGlobalFilter(!isGlobalFilter);
  };
  //// ---------------API Calling Function code start
  //// handle set params
  const handleAPIParams = () => {
    const inputData = {
      company_Code: companyDetails ? companyDetails.company_Code : "",
      type: selectAnalysisType,
      search: selectedBarFilter
        ? selectedBarFilter.map((item) => item.customerId).join(",")
        : "",
      pageindex:
        tableObjColumn.currentPage !== ""
          ? tableObjColumn.currentPage.toString()
          : "1",
      pagesize: "10",
      distributionChannel: selectedDistributionIds
        ? selectedDistributionIds.join(",")
        : "",
      salesOffice: selectedSalesOfficeIds
        ? selectedSalesOfficeIds.join(",")
        : "",
      priceGroup: selectedPriceGroupIds ? selectedPriceGroupIds.join(",") : "",
    };

    return inputData;
  };

  /// Get Customer And Currency Wise Outstanding listing API
  // const handleGetCustomerAndCurrencyWiseOutstandingData = () => {
  //   setIsLoading(true);
  //   let inputData = handleAPIParams();
  //   paymentService
  //     .getPaymentCustomerWisePendingDataAPI(inputData)
  //     .then((response) => {
  //       if (response.data.statusCode === 200) {
  //         let finaldata = response.data.responseData;
  //         setTabWiseTableData(finaldata);
  //       } else {
  //         NotificationManager.error(response.data.message);
  //       }
  //       setIsLoading(false);
  //     })
  //     .catch((error) => {
  //       setIsLoading(false);
  //       console.log(error, "Error");
  //     });
  // };
  /// Get Customer Aging Details listing API
  const handleGetCustomerAgingDetailsAPI = () => {
    setCustomerAgingLoading(true);
    let inputData = handleAPIParams();
    paymentService
      .getPaymentCustomerAgeingDataAPI(inputData)
      .then((response) => {
        if (response.data.statusCode === 200) {
          let finaldata = response.data.responseData;
          setCustomerAgingData(finaldata);
        } else {
          NotificationManager.error(response.data.message);
        }
        setCustomerAgingLoading(false);
      })
      .catch((error) => {
        setCustomerAgingLoading(false);
        console.log(error, "Error");
      });
  };
  /// Get Invoice Details listing API
  const handleGetInvoiceDetailsAPI = () => {
    setIsLoading(true);
    let inputData = handleAPIParams();

    paymentService
      .getPaymentInvoiceDataAPI(inputData)
      .then((response) => {
        if (response.data.statusCode === 200) {
          let finaldata = response.data.responseData;
          setInvoiceDetailsData(finaldata);
        } else {
          NotificationManager.error(response.data.message);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error, "Error");
      });
  };
  //// handle get dashboard counts API
  const handleGetPaymentDashboardCount = () => {
    let inputData = {
      company_Code: companyDetails ? companyDetails.company_Code : "",
      type: selectAnalysisType,
    };
    eliteCardService
      .getPaymentTileCountsAPI(inputData)
      .then((response) => {
        if (response.data.statusCode === 200) {
          let objData = response.data.responseData;
          if (Object.keys(objData).length > 0) {
            setTotalAmountLCYCount(objData.totalPendingAmount_LCY);
          } else {
            setTotalAmountLCYCount(0);
          }
        } else {
          NotificationManager.error(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error, "Error");
      });
  };

  //// handle Get Distribution Channel API
  const handleGetDistributionchannelData = () => {
    paymentService
      .getDistributionchannelDataAPI()
      .then((response) => {
        if (response.data.statusCode === 200) {
          let resultData = response.data.responseData;
          if (resultData.length > 0) {
            setDistributionChannelData(resultData);
          } else {
            setDistributionChannelData([]);
          }
        } else {
          NotificationManager.error(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error, "Error");
      });
  };
  //// handle Get Price Group API
  const handleGetPriceGroupData = () => {
    paymentService
      .getPrinceGroupDataAPI()
      .then((response) => {
        if (response.data.statusCode === 200) {
          let resultData = response.data.responseData;
          if (resultData.length > 0) {
            setPriceGroupData(resultData);
          } else {
            setPriceGroupData([]);
          }
        } else {
          NotificationManager.error(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error, "Error");
      });
  };
  //// handle Get Sales Office API
  const handleGetSalesOfficeData = () => {
    paymentService
      .getSalesOfficeDataAPI()
      .then((response) => {
        if (response.data.statusCode === 200) {
          let resultData = response.data.responseData;
          if (resultData.length > 0) {
            setSalesOfficeData(resultData);
          } else {
            setSalesOfficeData([]);
          }
        } else {
          NotificationManager.error(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error, "Error");
      });
  };
  //// ---------------API Calling Function code end

  //// Analysis Type changes
  const handleAnalysisTypeChange = (e) => {
    setSelectAnalysisType(e);
  };
  const handleSetSelectedBarChartData = (filter) => {
    setSelectedBarFilter(filter);
  };
  //// table sorting column
  const handleTableSortColumn = (data) => {
    setTableObjColumn({
      currentPage: data.currentPage,
      table: data.table,
    });
  };
  //// handle drop-down Distribution channel select all and deselect all
  const handleDistributionOnChange = (value) => {
    if (value.includes("all")) {
      if (isSelectDistributionAll) {
        setSelectedDistributionIds([]);
        setIsSelectDistributionAll(false);
      } else {
        const allIds = distributionChannelData.map((item) => item.id);
        setSelectedDistributionIds(allIds);
        setIsSelectDistributionAll(true);
      }
    } else {
      setSelectedDistributionIds(value);
      setIsSelectDistributionAll(
        value.length === distributionChannelData.length
      );
    }
  };
  //// handle drop-down Price group select all and deselect all
  const handlePriceGroupOnChange = (value) => {
    if (value.includes("all")) {
      if (isSelectPriceGroupAll) {
        setSelectedPriceGroupIds([]);
        setIsSelectPriceGroupAll(false);
      } else {
        const allIds = priceGroupData.map((item) => item.id);
        setSelectedPriceGroupIds(allIds);
        setIsSelectPriceGroupAll(true);
      }
    } else {
      setSelectedPriceGroupIds(value);
      setIsSelectPriceGroupAll(value.length === priceGroupData.length);
    }
  };
  //// handle drop-down Sales Office select all and deselect all
  const handleSalesOfficeOnChange = (value) => {
    if (value.includes("all")) {
      if (isSelectSalesOfficeAll) {
        setSelectedSalesOfficeIds([]);
        setIsSelectSalesOfficeAll(false);
      } else {
        const allIds = salesOfficeData.map((item) => item.id);
        setSelectedSalesOfficeIds(allIds);
        setIsSelectSalesOfficeAll(true);
      }
    } else {
      setSelectedSalesOfficeIds(value);
      setIsSelectSalesOfficeAll(value.length === salesOfficeData.length);
    }
  };

  //// handle filter reset data with load default data
  const handleResetFilterData = () => {
    setSelectedDistributionIds([]);
    setSelectedPriceGroupIds([]);
    setSelectedSalesOfficeIds([]);
    setIsSelectDistributionAll(false);
    setIsSelectPriceGroupAll(false);
    setIsSelectSalesOfficeAll(false);
  };
  return (
    <Fragment>
      <div className="app-content content">
        <div className="content-wrapper">
          <div className="content-body">
            <h5 class="content-header-title d-inline-block">Payment</h5>
            <div className="main-setting">
              <Button
                className={`setting-button ${drawerOpen ? "open" : ""}`}
                icon={<SettingOutlined spin />}
                onClick={() => showFilterDrawer()}
              />
            </div>
            <Spin size="large" spinning={false}>
              <div class="row">
                <div class={cardClasses}>
                  <div class="card bg_info">
                    <div class="card-content">
                      <div class="card-body order images_bg">
                        <h4 class="text-white font24">
                          <img
                            src={SmallOrder}
                            className="card_small_img"
                            alt="Img"
                          />
                          <div style={{ display: "grid" }}>
                            <span style={{ fontSize: "22px" }}>
                              Total Orders
                            </span>
                            <label>1234</label>
                          </div>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div class={cardClasses}>
                  <div class="card bg_warning">
                    <div class="card-content">
                      <div class="card-body Materials images_bg">
                        <h4 class="text-white font24">
                          <img
                            src={SmallMaterial}
                            className="card_small_img"
                            alt="Img"
                          />
                          <div style={{ display: "grid" }}>
                            <span style={{ fontSize: "22px" }}>
                              In Production
                            </span>
                            <label>{1234}</label>
                          </div>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div class={cardClasses}>
                  <div class="card bg_danger">
                    <div class="card-content">
                      <div class="card-body Quantity images_bg ">
                        <h4
                          class="text-white font24"
                          style={{ textDecoration: "none" }}
                        >
                          <img
                            src={SmallQuantity}
                            className="card_small_img"
                            alt="Img"
                          />
                          <div style={{ display: "grid" }}>
                            <span style={{ fontSize: "22px" }}>
                              In Dispatch
                            </span>
                            <label>{1234}</label>
                          </div>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div class={cardClasses}>
                  <div class="card  bg_success">
                    <div class="card-content">
                      <div class="card-body MTQuantity images_bg">
                        <h4 class="text-white font24">
                          <img
                            src={SmallMTQ}
                            className="card_small_img"
                            alt="Img"
                          />
                          <div style={{ display: "grid" }}>
                            <span style={{ fontSize: "22px" }}>Delivered</span>
                            <label>{1234}</label>
                          </div>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Spin>
            <div className="row payment_tab payment_card1">
              <div className="col-md-10 card_10">
                <div className="d-flex justify-content-between pb-30">
                  <label>Outstanding Analysis</label>
                  <span
                    className="reset_filter_spn"
                    onClick={handleResetFilterData}
                  >
                    Reset filter
                    <img src={imgFilter} alt="img" className="pb_5" />
                  </span>
                </div>
                <div className="d-flex" style={{ overflow: "auto" }}>
                  <div className="mr_12">
                    <Select
                      mode="multiple"
                      value={selectedDistributionIds}
                      optionFilterProp="children"
                      placeholder="Select Distribution"
                      onChange={handleDistributionOnChange}
                      showSearch={false}
                      showArrow
                    >
                      <Option
                        key="all"
                        value="all"
                        label={
                          isSelectDistributionAll
                            ? "Deselect All"
                            : "Select All"
                        }
                      >
                        {isSelectDistributionAll
                          ? "Deselect All"
                          : "Select All"}
                      </Option>
                      {distributionChannelData.map((item, i) => {
                        return (
                          <Option value={item.id} key={i}>
                            {item.channel_Name ? item.channel_Name : "(Blank)"}
                          </Option>
                        );
                      })}
                    </Select>
                  </div>
                  <div className="mr_12">
                    <Select
                      mode="multiple"
                      optionFilterProp="children"
                      placeholder="Select Country"
                      showSearch={false}
                      showArrow
                    >
                      <Option
                        key="all"
                        value="all"
                        // label={isSelectAll ? "Deselect All" : "Select All"}
                        label="Select All"
                      >
                        Select All
                      </Option>
                      <Option>Option 1</Option>
                      <Option>Option 2</Option>
                    </Select>
                  </div>
                  <div className="mr_12">
                    <Select
                      mode="multiple"
                      value={selectedPriceGroupIds}
                      optionFilterProp="children"
                      placeholder="Select Price Group"
                      onChange={handlePriceGroupOnChange}
                      showSearch={false}
                      showArrow
                    >
                      <Option
                        key="all"
                        value="all"
                        label={
                          isSelectPriceGroupAll ? "Deselect All" : "Select All"
                        }
                      >
                        {isSelectPriceGroupAll ? "Deselect All" : "Select All"}
                      </Option>
                      {priceGroupData.map((item, i) => {
                        return (
                          <Option value={item.id} key={i}>
                            {item.group_Name ? item.group_Name : "(Blank)"}
                          </Option>
                        );
                      })}
                    </Select>
                  </div>
                  <div className="mr_12">
                    <Select
                      mode="multiple"
                      optionFilterProp="children"
                      placeholder="Select Sales District"
                      showSearch={false}
                      showArrow
                    >
                      <Option
                        key="all"
                        value="all"
                        // label={isSelectAll ? "Deselect All" : "Select All"}
                        label="Select All"
                      >
                        Select All
                      </Option>
                      <Option>Option 1</Option>
                      <Option>Option 2</Option>
                    </Select>
                  </div>
                  <div className="mr_12">
                    <Select
                      mode="multiple"
                      value={selectedSalesOfficeIds}
                      optionFilterProp="children"
                      placeholder="Select Sales Office"
                      onChange={handleSalesOfficeOnChange}
                      showSearch={false}
                      showArrow
                    >
                      <Option
                        key="all"
                        value="all"
                        label={
                          isSelectSalesOfficeAll ? "Deselect All" : "Select All"
                        }
                      >
                        {isSelectSalesOfficeAll ? "Deselect All" : "Select All"}
                      </Option>
                      {salesOfficeData.map((item, i) => {
                        return (
                          <Option value={item.id} key={i}>
                            {item.location ? item.location : "(Blank)"}
                          </Option>
                        );
                      })}
                    </Select>
                  </div>
                </div>
              </div>
              <div className="col-md-2 card_2">
                <div className="left_section_card">
                  <img src={imgBank} alt="img" />
                  <label className="count">{totalAmountLCYCount}</label>
                  <span>Total Outstanding LCY</span>
                </div>
              </div>
            </div>
            <div className="row mt_18 payment_card1">
              <div className="col-md-10 card_chart payment_tab">
                <div className="dis_flex">
                  <label className="pt-8">Invoice vs Outstanding Graph</label>
                  <div className="pl-20">
                    <Select
                      optionFilterProp="children"
                      placeholder="Select Year"
                      showSearch={false}
                      showArrow
                    >
                      <Option value={"2024"}>2024</Option>
                      <Option value={"2023"}>2023</Option>
                      <Option value={"2022"}>2022</Option>
                      <Option value={"2021"}>2021</Option>
                    </Select>
                    <Select
                    className="pl-20"
                      optionFilterProp="children"
                      placeholder="Select Quarter"
                      showSearch={false}
                      showArrow
                    >
                      <Option key="all" value="all" label="Select All">
                        Select All
                      </Option>
                      <Option value={"Quarter1"}>Quarter 1</Option>
                      <Option value={"Quarter2"}>Quarter 2</Option>
                      <Option value={"Quarter3"}>Quarter 3</Option>
                      <Option value={"Quarter4"}>Quarter 4</Option>
                    </Select>
                  </div>
                </div>
                <InvoiceOutstandingBarChart />
              </div>
            </div>
            <div className="pay_section_1">
              <div className="row payment_tab">
                <div className="col-md-3">
                  <label className="analy_type">Select Analysis Type</label>
                </div>
                <div className="col-md-9">
                  <Tabs
                    activeKey={selectAnalysisType}
                    className="tab_panel"
                    type="card"
                    onChange={handleAnalysisTypeChange}
                  >
                    <TabPane tab="Credit Days" key="C" />
                    <TabPane tab="Overdue" key="O" />
                    <TabPane tab="Planning Due Date" key="P" />
                    <TabPane tab="Total Days" key="T" />
                  </Tabs>
                </div>
              </div>
            </div>
            <Card className="pay_section_2">
              <div className="row">
                <div className="col-md-12">
                  <CustomerTab
                    selectAnalysisType={selectAnalysisType}
                    selectedDistributionIds={selectedDistributionIds}
                    selectedPriceGroupIds={selectedPriceGroupIds}
                    selectedSalesOfficeIds={selectedSalesOfficeIds}
                    SelectedBarChartData={handleSetSelectedBarChartData}
                  />
                </div>
              </div>
            </Card>
            <div className="pay_section_3">
              <div className="row payment_tab">
                <div className="col-md-4">
                  <label className="analy_type">Customer Aging Details</label>
                </div>
              </div>
            </div>
            <Card className="pay_section_2">
              <div className="row">
                <div className="col-md-12">
                  <CustomerAgingTbl
                    customerAgingData={customerAgingData}
                    customerAgingLoading={customerAgingLoading}
                    OnhandleTableSortColumn={handleTableSortColumn}
                  />
                </div>
              </div>
            </Card>
            <div className="pay_section_3">
              <div className="row payment_tab">
                <div className="col-md-4">
                  <label className="analy_type">Invoice Details</label>
                </div>
              </div>
            </div>
            <Card className="pay_section_2">
              <div className="row">
                <div className="col-md-12">
                  <InvoiceDetailsTbl
                    invoiceDetailsData={invoiceDetailsData}
                    isLoading={isLoading}
                    OnhandleTableSortColumn={handleTableSortColumn}
                  />
                </div>
              </div>
            </Card>
          </div>
        </div>
      </div>

      <div className="main-filter">
        <GlobalFilterDrawer
          isGlobalFilter={isGlobalFilter}
          showFilterDrawer={showFilterDrawer}
          isEmployee={isEmployee}
          applyFilter={handleApplyFilter}
        />
      </div>
    </Fragment>
  );
};

export default PaymentDashboard;
